"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTriggerJson = exports.EmailTriggerModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
const recipient_model_1 = require("./recipient-model");
exports.EmailTriggerModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    eventDetailType: typebox_1.Type.String(),
    eventMessageId: typebox_1.Type.String(),
    eventSourceType: typebox_1.Type.String(),
    recipients: typebox_1.Type.Array(recipient_model_1.Recipient),
    templateId: typebox_1.Type.String(),
    templateData: model_helpers_1.JsonValue,
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.EmailTriggerJson = exports.EmailTriggerModel;
