"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoreJson = exports.CoreModel = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_helpers_1 = require("../model-helpers");
const timestamp_json_1 = require("../timestamp-json");
/**
 * Defines the JSON serialized shape of attendee data records.
 */
exports.CoreModel = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    fixedFlag: (0, model_helpers_1.Nullable)(typebox_1.Type.Boolean()),
    name: typebox_1.Type.String(),
    componentID: typebox_1.Type.String({ format: 'uuid' }),
    componentFieldData: (0, model_helpers_1.Nullable)(model_helpers_1.JsonValue),
    variables: (0, model_helpers_1.Nullable)(model_helpers_1.JsonValue),
    adminUI: (0, model_helpers_1.Nullable)(model_helpers_1.JsonValue),
    coreType: (0, model_helpers_1.Nullable)(typebox_1.Type.String()),
    ...timestamp_json_1.TimestampsJson.properties,
});
/**
 * Defines the understood JSON serialized shapes that can be converted into
 * attendee data records.
 */
exports.CoreJson = exports.CoreModel;
