import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useManagedRef } from '@backstage-components/base';
import { RouterContainer } from '@backstage-components/router';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate as useRouterNavigate, } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
export const UiRouter = (props) => {
    return (_jsx(Router, { children: _jsx(UiRouterInternal, { ...props }) }));
};
/**
 * `UiRouterInternal` is necessary to take advantage of the `useNavigate` hook
 * from `react-router` so `UiRouterInternal` can be located inside a `Router`
 * component instance.
 */
const UiRouterInternal = (props) => {
    // `Route` needs to be a direct child of `Router`. `PageRoute` cannot
    // encapsulate `Route` so assemble the `Route` and `PageRoute` elements in
    // multiple passes
    const { isLoading, prefix, fallbackElement: FallbackComponent, pageRoutes, } = props;
    const isLoadingRef = useManagedRef(isLoading);
    const pendingNavigation = useRef();
    const navigateFn = useRouterNavigate();
    // The `navigate` callback needs to use the latest value of `isLoading` but
    // can not be recreated each time `isLoading` toggles so the incoming
    // `isLoading` value is stored in a ref that is updated on every render
    const navigate = useCallback((...args) => {
        if (isLoadingRef.current) {
            // If page is in a loading state delay the navigation
            pendingNavigation.current = args;
            return;
        }
        else {
            // Clear `pendingNavigation` and perform the given navigation
            pendingNavigation.current = undefined;
            performNavigate(navigateFn, args);
        }
    }, [isLoadingRef, navigateFn]);
    // Perform `pendingNavigation` (if there is one) when `isLoading` changes
    useEffect(() => {
        const args = pendingNavigation.current;
        // The `useEffect` triggers when `isLoading` changes so do not use the
        // `isLoadingRef` here
        if (!isLoading && Array.isArray(args)) {
            pendingNavigation.current = undefined;
            performNavigate(navigate, args);
        }
    }, [isLoading, navigate]);
    return (_jsxs(RouterContainer, { prefix: prefix, navigate: navigate, children: [pageRoutes.map((pageRoute) => {
                return (_jsx(Route, { path: pageRoute.pathname, element: pageRoute.component }, pageRoute.pathname));
            }), _jsx(Route, { element: FallbackComponent, path: "*" })] }));
};
/**
 * Execute the given `NavigateFunction` with the given arguments. This wrapper
 * exists for TypeScript's benefit because the type of `NavigateFunction` is a
 * type overload the `args` need to be disambiguated before calling the
 * `NavigateFunction`.
 */
function performNavigate(navigate, args) {
    if (typeof args[0] === 'number') {
        const delta = args[0];
        navigate(delta);
    }
    else {
        const to = args[0];
        const options = args[1];
        navigate(to, options);
    }
}
