import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Fragment, Suspense } from 'react';
const componentList = {
    AccessCode: lazy(() => import('@backstage-components/access-code').then(({ AccessCodeComponent }) => ({ default: AccessCodeComponent }))),
    AddEvent: lazy(() => import('@backstage-components/add-event').then(({ AddEventComponent }) => ({
        default: AddEventComponent,
    }))),
    Button: lazy(() => import('@backstage-components/button').then(({ ButtonComponent }) => ({
        default: ButtonComponent,
    }))),
    Checkbox: lazy(() => import('@backstage-components/checkbox').then(({ CheckboxComponent }) => ({
        default: CheckboxComponent,
    }))),
    CnnQuiz: lazy(() => import('@backstage-components/cnn-quiz').then(({ CnnQuizComponent }) => ({
        default: CnnQuizComponent,
    }))),
    CnnxLanding: lazy(() => import('@backstage-components/cnnx-landing').then(({ CnnxLandingComponent }) => ({
        default: CnnxLandingComponent,
    }))),
    Countdown: lazy(() => import('@backstage-components/countdown').then(({ CountdownComponent }) => ({
        default: CountdownComponent,
    }))),
    CustomCursor: lazy(() => import('@backstage-components/custom-cursor').then(({ CustomCursorComponent }) => ({
        default: CustomCursorComponent,
    }))),
    Embed: lazy(() => import('@backstage-components/embed').then(({ EmbedComponent }) => ({
        default: EmbedComponent,
    }))),
    Confetti: lazy(() => import('@backstage-components/confetti').then(({ ConfettiComponent }) => ({
        default: ConfettiComponent,
    }))),
    Image: lazy(() => import('@backstage-components/image').then(({ ImageComponent }) => ({
        default: ImageComponent,
    }))),
    Intercom: lazy(() => import('@backstage-components/intercom').then(({ IntercomComponent }) => ({
        default: IntercomComponent,
    }))),
    Link: lazy(() => import('@backstage-components/link').then(({ LinkComponent }) => ({
        default: LinkComponent,
    }))),
    PfLockerRoom: lazy(() => import('@backstage-components/pf-locker-room').then(({ PfLockerRoomComponent }) => ({
        default: PfLockerRoomComponent,
    }))),
    PhotoboothGallery: lazy(() => import('@backstage-components/photobooth-gallery').then(({ PhotoboothGalleryComponent }) => ({
        default: PhotoboothGalleryComponent,
    }))),
    PublicAccessCode: lazy(() => import('@backstage-components/public-access-code').then(({ PublicAccessCodeComponent }) => ({ default: PublicAccessCodeComponent }))),
    QRCode: lazy(() => import('@backstage-components/qr-code').then(({ QRCodeComponent }) => ({
        default: QRCodeComponent,
    }))),
    Radio: lazy(() => import('@backstage-components/radio').then(({ RadioComponent }) => ({
        default: RadioComponent,
    }))),
    RichText: lazy(() => import('@backstage-components/rich-text').then(({ RichTextComponent }) => ({
        default: RichTextComponent,
    }))),
    Slideshow: lazy(() => import('@backstage-components/slideshow').then(({ SlideshowComponent }) => ({
        default: SlideshowComponent,
    }))),
    Switch: lazy(() => import('@backstage-components/switch').then(({ SwitchComponent }) => ({
        default: SwitchComponent,
    }))),
    TextInput: lazy(() => import('@backstage-components/text-input').then(({ TextInputComponent }) => ({
        default: TextInputComponent,
    }))),
    WbdScreeningBackground: lazy(() => import('@backstage-components/wbd-screening-background').then(({ WbdScreeningBackgroundComponent }) => ({
        default: WbdScreeningBackgroundComponent,
    }))),
    Grid: lazy(() => import('@backstage-components/grid').then(({ GridComponent }) => ({
        default: GridComponent,
    }))),
    Chat: lazy(() => import('@backstage-components/chat').then(({ ChatComponent }) => ({
        default: ChatComponent,
    }))),
    RtcDeviceLists: lazy(() => import('@backstage-components/rtc-device-lists').then(({ RtcDeviceListsComponent }) => ({
        default: RtcDeviceListsComponent,
    }))),
    LocalVideoPlayer: lazy(() => import('@backstage-components/local-video-player').then(({ LocalVideoPlayerComponent }) => ({
        default: LocalVideoPlayerComponent,
    }))),
    RtcParticipantVideo: lazy(() => import('@backstage-components/rtc-participant-video').then(({ RtcParticipantVideoComponent }) => ({
        default: RtcParticipantVideoComponent,
    }))),
    RtcRemoteParticipantAudioToggle: lazy(() => import('@backstage-components/rtc-remote-participant-audio-toggle').then(({ RtcRemoteParticipantAudioToggleComponent }) => ({
        default: RtcRemoteParticipantAudioToggleComponent,
    }))),
    RtcRemoteParticipantVideoToggle: lazy(() => import('@backstage-components/rtc-remote-participant-video-toggle').then(({ RtcRemoteParticipantVideoToggleComponent }) => ({
        default: RtcRemoteParticipantVideoToggleComponent,
    }))),
    Vimeo: lazy(() => import('@backstage-components/vimeo').then(({ VimeoComponent }) => ({
        default: VimeoComponent,
    }))),
    MagicText: lazy(() => import('@backstage-components/magic-text').then(({ MagicTextComponent }) => ({
        default: MagicTextComponent,
    }))),
    // DropDown: placeholder,
    // Modal: placeholder,
    // MoreInfoOverlay: placeholder,
    // Overlay: placeholder,
    // PhotoboothContainer: placeholder,
    // PhotoboothGallery: placeholder,
};
export const ComponentController = (definition) => {
    if (componentList[definition.component]) {
        // Not sure how to make type of `DefineComponent` match `definition` but
        // `componentList` properly checks that only an appropriate Component can be
        // assigned so should be safe
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const DefinedComponent = componentList[definition.component];
        return (_jsx(Suspense, { fallback: _jsx(Fragment, {}), children: _jsx(DefinedComponent, { ...definition }) }));
    }
    else {
        return _jsx("div", { children: definition.component });
    }
};
