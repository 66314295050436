import { familyTree, getModuleProps, applyPropsDiff, computeId, buildXmlStructure, } from '@backstage/module-tree-shared';
import { arrayToRecord } from './array-to-lookup';
function nodeToPageModule({ config, node, page, all, tree, vars = {}, groupInstanceData, }) {
    vars = Object.assign({}, vars);
    const module = all.modules[node.id];
    const core = all.cores[module.coreId];
    const component = all.components[core.componentId];
    const props = core.componentFieldData;
    if (typeof props !== 'object' || Array.isArray(props) || props === null) {
        throw new Error(`componentFieldData for Module(${node.id}) was not an object.`);
    }
    /*
     * Props are mainly stored in core.componentFieldData, but there are overrides
     * and one "underride" that's not been written yet.
     * -1 "presets" are set before core's props. But overrides can change the preset.
     *  0 core props "componentFieldData"
     *  1 moduleProps which are overrides at the module level (of the boss module)
     *  2 staffProps which are overrides at the group level. When we allow groups within
     *    groups, this will be layered more, but those will come through the same object.
     */
    // `groupInstanceData` is only to be passed down to modules within this instance
    // of this group, so we must establish a boundary for where overrides can be applies.
    // Currently, the only modules that have a path of length>0 are modules
    // that are simply situated in a group's open slot. Such a module CAN be
    // the boss module of another group instance, but not of this group instance,
    // so set groupInstanceData = null as a boundary.
    if (module.path.length > 0) {
        groupInstanceData = null;
    }
    const isBoss = core.coreType === 'group';
    // These only get populated by the top level of a group; staff modules or ungrouped will get nulls here.
    const { moduleProps, staffOverrides } = getModuleProps(core, module);
    if (isBoss) {
        groupInstanceData = {
            groupId: core.id,
            groupModuleId: module.id,
            overrides: staffOverrides ?? null,
        };
    }
    // If overrides exist, see if any are applicable to this module.
    const staffProps = module.groupId
        ? groupInstanceData?.overrides?.[node.id] ?? null
        : null;
    // Determine the override set to use.
    const myOverrides = isBoss ? moduleProps : staffProps;
    // If the override set is not null, apply it.
    const propsToUse = myOverrides ? applyPropsDiff(props, myOverrides) : props;
    // Since we're building up based on family tree, we don't need all this.
    // NEXT: variableResolver(vars, module.variables, core.variables, core.componentFieldData);
    // Unlike modules and cores, components don't need to be cloned, I think.
    // TODO: Add i18n to this.
    // const props = Object.assign(
    //   {},
    //   isJSONObject(core.componentFieldData) ? core.componentFieldData : {},
    //   isJSONObject(core.i18n?.[1]?.data) ? core.i18n?.[1]?.data : {},
    //   isJSONObject(core.i18n?.[0]?.data) ? core.i18n?.[0]?.data : {}
    // );
    // pre-compute these for later.
    const mid = module.id;
    const groupModuleId = (groupInstanceData?.groupModuleId !== mid &&
        groupInstanceData?.groupModuleId) ||
        null;
    const rv = {
        component: component.reactName,
        config: {
            scope: 'attendee',
            attendeeApiEndpoint: `${config.endpointBase}/attendee`,
            legacyEndpoint: config.legacyEndpoint,
            bitmovinKey: config.bitmovinKey,
            bitmovinAnalyticsKey: config.bitmovinAnalyticsKey,
            streamKey: config.streamKey,
            timestampEndpoint: `${config.endpointBase}/timestamp`,
            livekitEndpoint: config.livekitEndpoint,
        },
        id: computeId({ mid, groupModuleId }),
        mid,
        cid: core.id,
        gid: module.groupId || null,
        groupModuleId,
        name: node.name,
        path: module.path || [],
        props: propsToUse,
    };
    page.pageModuleLookup[rv.id] = rv;
    node.groupCoreId = rv.gid;
    node.groupModuleId = rv.groupModuleId;
    node.htmlId = rv.id;
    node.slot = module.parentSlotSlug;
    if ('kids' in node && node.kids) {
        rv.slots = {};
        node.kids.forEach((kid) => {
            const km = all.modules[kid.id];
            if (rv.slots && km.parentSlotSlug) {
                let slot = rv.slots[km.parentSlotSlug];
                if (!slot) {
                    slot = rv.slots[km.parentSlotSlug] = [];
                }
                if (Array.isArray(slot)) {
                    slot.push(nodeToPageModule({
                        config,
                        node: kid,
                        page,
                        all,
                        tree,
                        vars,
                        groupInstanceData,
                    }));
                }
            }
        });
    }
    return rv;
}
export function buildUpModules({ config, page, showId, domainName, }) {
    const all = {
        components: arrayToRecord(page.allComponents, 'id'),
        cores: arrayToRecord(page.allCores, 'id'),
        modules: arrayToRecord(page.allModules, 'id'),
    };
    const rootModules = page.allModules
        .filter((x) => x.pageIndex !== null)
        .sort((a, b) => (a.pageIndex ?? 0) - (b.pageIndex ?? 0));
    const tree = familyTree(rootModules, all);
    const pageModuleLookup = {};
    const pageWithLookup = { ...page, pageModuleLookup };
    const modules = tree.map((x) => nodeToPageModule({ config, node: x, page: pageWithLookup, all, tree }));
    const structure = buildXmlStructure({
        page: pageWithLookup,
        tree,
        showId,
        domainName,
        all,
    });
    return Object.assign({}, pageWithLookup, { modules, structure });
}
