import {AttendeeProvider} from '@backstage-components/attendee-container';
import type {AppPage} from '@backstage-components/base';
import {ShowInstructionsProvider} from '@backstage-components/base';
import {FC, PropsWithChildren} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {client} from './apollo';
import {config} from './config';
import {
  useAnalytics,
  useFetchInstructions,
  type UseFetchInstructionsOptions,
} from './hooks';

export const AppContext: FC<PropsWithChildren<AppContextProps>> = ({
  appPages,
  children,
  domainName,
  showControllerType = 'POLL',
  showId = FALLBACK_SHOW_ID,
  status,
}) => {
  const instructionFetcher = useFetchInstructions({
    appKey: config.pusherKey,
    cluster: config.pusherCluster,
    showControllerType,
    showId,
    status,
  });
  const analytics = useAnalytics();
  return (
    <ShowInstructionsProvider
      analyticsEndpoint={analytics?.endpoint}
      analyticsToken={analytics?.token}
      appPages={appPages}
      domainName={domainName ?? 'unknown'}
      fetchInstructions={instructionFetcher}
      showId={showId}
    >
      <AttendeeProvider client={client} showId={showId}>
        <HelmetProvider>{children}</HelmetProvider>
      </AttendeeProvider>
    </ShowInstructionsProvider>
  );
};

const FALLBACK_SHOW_ID = '00000000-0000-0000-0000-000000000000';

interface AppContextProps
  extends Pick<
    UseFetchInstructionsOptions,
    'showControllerType' | 'showId' | 'status'
  > {
  appPages: AppPage[];
  /** Domain on which the `showId` was viewed */
  domainName?: string;
}
