import type {SiteDetailsByDomainQuery} from '@backstage/attendee-ui-types';
import {useMemo} from 'react';

type Site = SiteDetailsByDomainQuery['site'];

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

/**
 * Parses Url to find valid show associated with valid site
 * @param shows the list of shows to compare to the `pathShowId` to determine the current show id
 * @param pathShowId showId candidate parsed out from the pathname of the `URL`
 */
export function useShowId(
  shows: Site['shows'],
  pathShowId?: string
): string | undefined {
  // Making the show ids into a string is a hack so that the `useMemo` hook
  // doesn't re-run if the values of `show.id` in `shows` hasn't changed. This
  // works because strings with the same value are actually the same string in
  // JavaScript
  const flatShowIds = shows.map((show) => show.id).join(',');
  const showId = useMemo(
    () =>
      extractShowId(
        flatShowIds.split(',').filter((id) => id !== ''),
        pathShowId
      ),
    [flatShowIds, pathShowId]
  );

  return showId;
}

function extractShowId(
  siteShowIds: string[],
  pathShowId?: string
): string | undefined {
  if (
    pathShowId &&
    uuidRegex.test(pathShowId) &&
    (siteShowIds.includes(pathShowId) || siteShowIds.length === 0)
  ) {
    return pathShowId;
  } else if (siteShowIds.length > 0) {
    return siteShowIds[0];
  } else {
    return undefined;
  }
}
