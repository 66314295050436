import {
  QueryShowInstructions,
  type ShowInstructionsQuery as GetShowInstructionsQuery,
  type ShowInstructionsQueryVariables as GetShowInstructionsVariables,
  type SiteDetails,
} from '@backstage/attendee-ui-types';
import {GuestPushMessage} from '@backstage/api-types/guest-push';
import type {ApiInstruction} from '@backstage/instructions';
import {
  isJSONObject,
  isJSONValue,
  type FetchInstructionsFn,
} from '@backstage-components/base';
import Pusher, {Channel} from 'pusher-js';
import {useEffect, useMemo} from 'react';
import {fromEventPattern, isObservable, startWith} from 'rxjs';
import {client} from '../apollo';

type FetchInstructionsResponse = Awaited<ReturnType<FetchInstructionsFn>>;

const FALLBACK_SHOW_ID = '00000000-0000-0000-0000-000000000000';

// The `notificationType`s which may be published to a `GuestPushService`
const subscribeTo = GuestPushMessage.anyOf.map(
  (s) => s.properties.notificationType.const
);

/** Create a `Pusher` instance with the given options */
export function useFetchInstructions(
  options: UseFetchInstructionsOptions
): FetchInstructionsFn | undefined {
  const {
    appKey: appId = '',
    cluster = 'us2',
    showControllerType,
    showId = FALLBACK_SHOW_ID,
    status,
  } = options;
  // Create a pusher instance
  const pusher = useMemo(() => {
    if (showControllerType === 'POLL' || appId === '') {
      return undefined;
    } else if (showControllerType === 'REAL_TIME') {
      return new Pusher(appId, {cluster});
    } else {
      throw new Error(`Unrecognized showControllerType: ${showControllerType}`);
    }
  }, [appId, cluster, showControllerType]);
  // Create and subscribe to a `pusher` channel if using a 'REAL_TIME' show
  // controller. There's no way to unsubscribe from this pusher channel outside
  // of this hook, which is probably fine because the connection will be
  // terminated if the page reloads and if the channel details change
  const channel = useMemo(() => {
    if (
      showControllerType === 'POLL' ||
      status === 'unknown' ||
      typeof pusher === 'undefined'
    ) {
      return undefined;
    } else if (showControllerType === 'REAL_TIME') {
      const channel = pusher.subscribe(`${status}_${showId}`);
      return channel;
    } else {
      throw new Error(`Unrecognized showControllerType: ${showControllerType}`);
    }
  }, [pusher, showControllerType, showId, status]);
  // If the channel reference changes unsubscribe to the old channel
  useEffect(() => {
    return () => channel?.unsubscribe();
  }, [channel]);

  const fetcher = useMemo(() => {
    if (showId === FALLBACK_SHOW_ID) {
      return undefined;
    } else if (typeof channel === 'undefined') {
      return fetchInstructionList;
    } else {
      return fetchObservable.bind(null, channel);
    }
  }, [showId, channel]);

  return fetcher;
}

/**
 * The values required to determin the type of `FetchInstructionsFn` to create
 */
export interface UseFetchInstructionsOptions {
  /**
   * `key` of the pusher application, if not provided the polling show
   * controller will be used regardless of `showControllerType`.
   * @default ''
   */
  appKey?: string;
  /**
   * Cluster where app associated with `appKey` is located
   * @default 'us2'
   */
  cluster?: string;
  /** The type of show controller to use */
  showControllerType: SiteDetails['showControllerType'];
  /**
   * The id of the show whose instructions will be received. Falls back to an
   * impossible UUID.
   * @default '00000000-0000-0000-0000-000000000000'
   */
  showId: string | undefined;
  /**
   * The status of the site. 'draft' indicates this is a preview site,
   * 'published' indicates the site has been versioned and a version is being
   * viewed, 'unknown' indicates the status could not yet be determined.
   */
  status: 'draft' | 'published' | 'unknown';
}

/** Fetch list of instructions from the GraphQL API. */
const fetchInstructionList: FetchInstructionsFn = async (
  id,
  sinceInstructionId
) => {
  return client
    .query<GetShowInstructionsQuery, GetShowInstructionsVariables>({
      query: QueryShowInstructions,
      variables: {showId: id, sinceInstructionId: sinceInstructionId ?? null},
      context: {showId: id},
      // do not use the cache, if using the cache polling for new instructions
      // does not work because cached results are returned
      fetchPolicy: 'no-cache',
    })
    .then((result) => {
      return {
        data: result.data,
        error: result.error,
      };
    });
};

/**
 * Fetch instructions as an `Observable` that is "seeded" with the values from
 * `fetchInstructionList`. The `Observable` pulls data from the Pusher `Channel`
 * provided as the first argument.
 */
async function fetchObservable(
  channel: Channel,
  showId: string,
  sinceInstructionId?: string
): Promise<FetchInstructionsResponse> {
  let initial: ApiInstruction[] = [];
  if (typeof channel === 'undefined') {
    // If there's no `pusher` instance fall back to `fetchInstructionList`
    return fetchInstructionList(showId, sinceInstructionId);
  } else if (typeof sinceInstructionId === 'undefined') {
    // If this is the initial fetch then "seed" the observable with the initial
    // data fetch.
    const result = await fetchInstructionList(showId, sinceInstructionId);
    if (isObservable(result)) {
      throw new Error('`fetchInstructionList` returned an Observable');
    } else {
      initial = result.data?.showById?.showInstructions ?? [];
    }
  }
  // stop observable on `pusher:error`?
  const o = fromEventPattern(
    (handler) => {
      for (const event of subscribeTo) {
        channel.bind(event, handler);
      }
    },
    (handler) => {
      for (const event of subscribeTo) {
        channel.unbind(event, handler);
      }
      // If this `handler` is being removed then the `channel` is no longer
      // being listened to and we can unsubscribe from it.
      channel.unsubscribe();
    },
    (...messages: unknown[]) => {
      return messages.filter(isApiInstruction);
    }
  );
  const result = startWith<ApiInstruction[]>(initial)(o);
  return result;
}

/** Type guard to check the given `value` can be treated as an ApiInstruction */
function isApiInstruction(value: unknown): value is ApiInstruction {
  return (
    isJSONValue(value) &&
    isJSONObject(value) &&
    'id' in value &&
    typeof value.id === 'string' &&
    'kind' in value &&
    typeof value.kind === 'string' &&
    'meta' in value &&
    typeof value.meta === 'object' &&
    !Array.isArray(value.meta) &&
    value.meta !== null
  );
}
